import axios from "axios";
import React, { useState } from "react";
import { Form } from "semantic-ui-react";
import { Container, Spacer } from "ui-start";
import {
  ErrorMessage,
  StyledPageCopy,
  StyledPageSubHeading,
  Wrapper,
} from "./styles";
import { HeroImage } from "./heroImage";

const heroImage =
  "https://secureservercdn.net/160.153.138.143/pxg.aec.myftpupload.com/wp-content/themes/ELSA/images/slider-img-3.jpg";
export const ContactPage = () => {
  const [newsletterOptIn, setNewsletterOptIn] = useState(false);
  const [consent, setConsent] = useState(false);
  const [consentError, setConsentError] = useState(false);
  const [formError, setFormError] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);
  const [formValues, setFormValues] = useState();
  const handleConsent = (e) => {
    setConsent(!consent);
  };
  const handlesetNewsletterOptIn = (e) => {
    setNewsletterOptIn(!newsletterOptIn);
  };

  const handleSetFormValues = (e) => {
    setFormValues({
      ...formValues,
      to: 'elsanextgen@gmail.com',
      [e.target.getAttribute("name")]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !formValues?.["name"] ||
      !formValues?.["email"] ||
      !formValues?.["message"]
    ) {
      setFormError(true);
      return;
    } else {
      setFormError(false);
    }
    if (!consent) {
      setConsentError(true);
    } else {
      setConsentError(false);
      fetch("https://sheetdb.io/api/v1/swxlr42wquz8p?sheet=contact", {
        method: "POST",
        body: JSON.stringify({
          data: [formValues],
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      axios.post('https://proforma-email-service.onrender.com/send-email', formValues);
    }
    if (newsletterOptIn) {
      fetch("https://sheetdb.io/api/v1/swxlr42wquz8p?sheet=newsletter", {
        method: "POST",
        body: JSON.stringify({
          data: [formValues],
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
    }
    if (!consentError || !formError) {
      setFormSuccess(true);
      var frm = document.getElementsByName("contact-form")[0];
      frm.reset();
    }
  };

  return (
    <>
      <HeroImage page={'contact-us'} />
      <Wrapper>
        <Spacer height={32} />
        <StyledPageSubHeading>Send us a message</StyledPageSubHeading>
        <Container>
          <Form onSubmit={handleSubmit} name="contact-form">
            <Form.Group widths="equal">
              <Form.Input
                fluid
                label="Name"
                placeholder="Name"
                name="name"
                onChange={handleSetFormValues}
              />
              <Form.Input
                fluid
                label="Email"
                placeholder="Email"
                name="email"
                onChange={handleSetFormValues}
              />
              <Form.Input
                fluid
                label="Phone (Optional)"
                placeholder="Phone"
                name="phone"
                onChange={handleSetFormValues}
              />
              <Form.Input
                fluid
                label="Business (optional)"
                placeholder="Business"
                name="business"
                onChange={handleSetFormValues}
              />
            </Form.Group>
            <Form.TextArea
              label="Message"
              name="message"
              placeholder="Please put your message here"
              onChange={handleSetFormValues}
            />
            <Form.Checkbox
              label="I consent to ELSA Next Generation keeping my details for the purpose of contacting me"
              name="consent"
              onClick={handleConsent}
            />
            {consentError && (
              <ErrorMessage>You must check this box to continue</ErrorMessage>
            )}
            <Form.Checkbox
              label="I would like to be kept up to date with news from ELSA Next Generation"
              name="business"
              onChange={handlesetNewsletterOptIn}
            />
            {formError && (
              <ErrorMessage>Please fill in all required fields</ErrorMessage>
            )}
            {formSuccess && (
              <StyledPageCopy>
                Many thanks for contatcting ELSA! We will get back to you as
                soon as possible
              </StyledPageCopy>
            )}
            <Form.Button type="submit">Submit</Form.Button>
          </Form>
        </Container>
      </Wrapper>
    </>
  );
};
