import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { ContactPage } from "../components/contact";

export default function BlogPost({ data }) {
  const post = data.googleMenuPagesSheet;
  const [contactPage, setContactPage] = useState(false);

  useEffect(() => {
    if (window?.location?.pathname === "/contact-us") {
      setContactPage(true);
    }
  }, []);

  if (contactPage) {
    return (
      <Layout>
        <ContactPage />
      </Layout>
    );
  }

  return (
    <Layout>
      <div>
        <h1>{post.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: post.description }} />
      </div>
    </Layout>
  );
}
export const query = graphql`
  query($slug: String!) {
    googleMenuPagesSheet(slug: { eq: $slug }) {
      title
      description
    }
  }
`;
